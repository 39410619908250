import React from "react"

import { Title, Subtitle, Section, Container, Column, Columns } from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import ServiceCard from "../components/service-card"

const ServicePage = () => (
  <Layout>
    <SEO title="Services" description="Our Services" />
    <Header title="Services"></Header>
    <Section>
      <Container>
        <Columns>
          <Column>
            <ServiceCard
              title="Penetration Testing"
              description="Simulating a real attack, we'll provide you with the details of what we found, and recommendations on how to resolve them.​"
              icon="fas fa-bomb fa-3x has-text-primary"
            />
          </Column>
          <Column>
            <ServiceCard
              title="Vulnerability Scanning"
              description="Using industry standard tools, we'll use automated scans to show you what an attacker might see"
              icon="fas fa-bug fa-3x has-text-primary"
            />
          </Column>
          <Column>
            <ServiceCard
              title="Code Review"
              description="Using our experience in software, we'll review your source code to uncover security vulnerabilities."
              icon="fas fa-code fa-3x has-text-primary"
            />
          </Column>
          <Column>
            <ServiceCard
              title="Security Consultancy"
              description="We'll help to guide you towards solutions that meet your security needs and assist you with implementing them."
              icon="fas fa-comments fa-3x has-text-primary"
            />
          </Column>
        </Columns>
      </Container>
    </Section>

    {/* <Section>
      <Container>
         <Title></Title>
      </Container>
    </Section> */}
  </Layout>
)

export default ServicePage
