import React from "react"
import PropTypes from "prop-types"

import {
  Title,
  Subtitle,
  Section,
  Container,
  Button,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardContent,
  Content,
  Icon,
} from "bloomer"
import { Link } from "gatsby"

const ServiceCard = props => (
  <Section className="service-card">
    <Container>
      <Card>
        <Icon className={props.icon} id="feature-icon" hasTextAlign="centered"/>
          <CardHeaderTitle hasTextAlign="centered">{props.title}</CardHeaderTitle>
        <CardContent>
          <Content>{props.description}</Content>
        </CardContent>
      </Card>
    </Container>
  </Section>
)

export default ServiceCard

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
