import React from "react"
import PropTypes from "prop-types"

import {
  Title,
  Subtitle,
  Container,
} from "bloomer"
import { Link } from "gatsby"

const Header = (props) => (
  <div className="header header-bg">
    <Container>
          <Title>{props.title}</Title>
          <Subtitle>{props.subtitle}</Subtitle>
    </Container>
  </div>
)

export default Header


Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
